import React from "react";
// eslint-disable-next-line
import styles from "./CampaignDialogBox.module.css";
import { images } from "../../images";
import { useNavigate } from "react-router-dom";
import { ADV_POST_A_RIDE_TO_ROUTE } from "../../constants/routeConstants";

const CampaignDialogBox = ({ visibility = false, onCloseClick = () => {} }) => {
  // const navigate = useNavigate();
  // const advanceBookingPageClickHnalder = () => {
  //   //navigation
  //   navigate(`${ADV_POST_A_RIDE_TO_ROUTE}`);

  //   onCloseClick();
  // };

  return (
    <>
      {visibility && (
        <div className={styles.campaignDialogBox}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <img src={images.DriverBanner} alt="Campaign Banner" style={{width:"100%",height:"100%"}}/>
              <div className={styles.buttonRow}>
                <button
                  style={{
                    marginTop: "-1rem",
                    marginBottom: "-1rem",
                  }}
                  onClick={onCloseClick}
                  className={styles.button}
                >
                  {"Close"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignDialogBox;
