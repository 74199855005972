import { images } from "../images";

// Cookie
export const ACCESS_TOKEN_COOKIE_NAME = "access";

// Local Storage
// key
export const USER_AGENT = "userAgent";
// value
export const USER_AGENT_RIDER = "rider";
export const USER_AGENT_DRIVER = "driver";

export const DRIVER_NAME = "driver_name";

// error
export const UNDEFINED = "undefined";
export const NULL = "null";

export const USER_LOCAL_STORAGE = "_user_driver_rider_";

export const EMAIL_VALIDATION_REGEX =
  /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

// map selection center location
export const PUNE_AIRPORT_LATITUDE = 18.578164454287336;
export const PUNE_AIRPORT_LONGITUDE = 73.90803401551001;

export const DRIVER_CURRENT_LOCATION = "driver_current";

export const GLOBAL_VERSION_CONSTANT = "2.22.127";

export const TAXIWARS_EMAIL = "support@taxiwars.in";
export const HASH_MAP_HISTORY_SIZE_ARRAY = 20;

export const BASE_INTENT_LINK =
  "intent://driver.taxiwars.in#Intent;scheme=https;package=in.taxiwars.driver.twa;";

export const START_STOP = {
  start: "start",
  stop: "stop",
};

export const ANDROID_VERSION = "_android_version_";
export const CURRENT_LIVE_ANDROID_VERSION = 17;
export const SUPPORT_MOBILE_NUMBERS = [9284046124, 7499448917];
export const SUPPORT_EMAIL_ID = "support@taxiwars.in";

export const bottomAd = [
  {
    linkToOpen: "https://youtu.be/-mlhRJPFgyc?si=RX6sNaZju_xC0DS0",
    lefText: "Demo Video",
    rightImage: images.logoWithTextRow,
  },
];

export const PACKAGE_12_HOUR = "Package - 12 hours / Return Trip";
export const HOURS_12 = "12 Hours";
