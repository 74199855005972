import Cookies from "js-cookie";
import React from "react";

import {
  BottomAd,
  FloatingActionButton,
  HomescreenGoogleMap,
  InputWithLabel,
  LiveBidHeadingWithSort,
  LoadingSpinner,
  OnDutyOffDuty,
  CampaignDialogBox,
  OverduePopup,
  SelectVehicleTypeModal,
  SingleBidDetailCard,
  SingleBidDetailCardPlaceBid,
} from "../../../components";

import {
  campaignDialogBoxActions,
  riderPostRideActions,
} from "../../../store/slice";

import {
  ANALYTICS_LAST_APP_OPEN_DATE_TIME,
  API_FLASHING_URL,
  BASE_URL,
  DRIVER_CAR_DETAILS,
  DRIVER_GET_BID_CONFIRMED_RIDES,
  DRIVER_GET_PENDING_RIDES,
  DRIVER_IN_PROGRESS_RIDES,
  DRIVER_RC_CHECK,
  DRIVER_RC_UPLOAD,
  DRIVER_SEND_CURRENT_LOCATION,
  DRIVER_VIEW_ALL_RIDES,
  GET_DRIVER_VEHICLE_TYPE,
  RIDE_STATUS_ACTIVE,
  RIDE_STATUS_BID_CONFIRMED,
  RIDE_STATUS_CANCELLED,
  RIDE_STATUS_IN_PROGRESS,
  RIDE_STATUS_PENDING,
} from "../../../constants/apiConstants";
// eslint-disable-next-line
import styles from "./ViewRidesWithMyBids.module.css";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  bottomAd,
} from "../../../constants/storageConstants";
import { useDispatch, useSelector } from "react-redux";
import { driverAllRideActions } from "../../../store/slice";
import { useEffect } from "react";
import {
  useGeolocation,
  useLocalStorage,
  useLogoutHandler,
  useNavigateOnEvent,
  useOnDutyHook,
  usePreventBackBehaviour,
  useStopBackgroundLocationUpdate,
} from "../../../hooks";
import {
  LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
  ONE_SECOND,
  THIRTY_SECONDS,
} from "../../../constants/timeConstants";
import { useMemo } from "react";
import { useState } from "react";
import {
  ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
} from "../../../constants/routeConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { images } from "../../../images";

const ViewRidesWithMyBids = () => {
  usePreventBackBehaviour();
  useStopBackgroundLocationUpdate();
  const logoutFunction = useLogoutHandler();
  const navigate = useNavigate();
  //
  const analyticsLastAppOpenTime = () => {
    // calculating current date
    const currentDate = new Date();

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    console.log(formattedDate, formattedTime);

    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    // console.log("accessToken", accessToken);
    const url = `${BASE_URL}${ANALYTICS_LAST_APP_OPEN_DATE_TIME}`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        last_open_date: formattedDate,
        last_open_time: formattedTime,
      }),
    };

    // request
    fetch(url, options)
      .then((response) => {
        if (response.status === 403) {
          console.log("Auth Expired");
          logoutFunction();
        }
        console.log(response);
        return response.json();
      })
      .then((data) => {
        console.log(data);
      });
  };

  // function that needs to be run only once
  useEffect(() => {
    analyticsLastAppOpenTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  const navigateToInProgressEndRide = useNavigateOnEvent(
    DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE
  );
  const [location, setLocation] = useState();
  const [vehicleTypeData, setVehicleTypeData] = useState({});

  const [showFloatingActionButton, setShowFloatingActionButton] =
    useState(false);

  const [dataForInProgressRide, setDataForInProgressRide] = useState("");

  const options = useMemo(
    () => ({
      enableHighAccuracy: true,
      maximumAge: 1 * 1000,
      timeout: LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
    }),
    []
  );

  const {
    loading: loadingGeoLoc,
    error: errorGeoLoc,
    data: dataGeoLoc,
  } = useGeolocation(options);

  useEffect(() => {
    if (loadingGeoLoc) return;
    if (errorGeoLoc) return;

    // console.log("Data current location - ");
    // console.log(dataGeoLoc);

    setLocation({
      latitude: dataGeoLoc.latitude,
      longitude: dataGeoLoc.longitude,
    });
  }, [loadingGeoLoc, errorGeoLoc, dataGeoLoc]);

  const dispatch = useDispatch();

  const rideList = useSelector((state) => state.driverAllRide.rides);

  const pendingRideList = useSelector(
    (state) => state.driverAllRide.pendingRides
  );

  const bidConfirmedRideList = useSelector(
    (state) => state.driverAllRide.bidConfirmedRides
  );

  const [driverName] = useLocalStorage("driver_name");

  //to run only once
  useEffect(() => {
    // Active Rides
    const updateRides = () => {
      // console.log("Fetch request sent..");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${DRIVER_VIEW_ALL_RIDES}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log("Driver view al rides result - ", result);
          if (!Array.isArray(result)) {
            dispatch(driverAllRideActions.updateRides([]));
            return;
          }
          dispatch(driverAllRideActions.updateRides(result));
        })
        .catch((error) => console.log("ViewRidesWithMyBids.js, error", error));
    };
    // pending rides
    const updateRidesPending = () => {
      // console.log("Fetch request for pending rides sent..");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${DRIVER_GET_PENDING_RIDES}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          // console.log("Driver view pending rides response - ");
          // console.log(response);
          return response.json();
        })
        .then((result) => {
          // console.log("Driver view pending rides result - ");
          // console.log(result);
          if (!Array.isArray(result)) {
            dispatch(driverAllRideActions.updatePendingRides([]));
            return;
          }
          dispatch(driverAllRideActions.updatePendingRides(result));
        })
        .catch((error) =>
          console.log("ViewRidesWithMyBids.js, Pending Rides error", error)
        );
    };

    // bid confirmed
    const updateRidesBidConfirmed = () => {
      // console.log("Fetch request for pending rides sent..");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${DRIVER_GET_BID_CONFIRMED_RIDES}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          // console.log("response - ", response);
          return response.json();
        })
        .then((result) => {
          // console.log("Driver view bid confirmed rides result - ", result);
          if (!Array.isArray(result)) {
            dispatch(driverAllRideActions.updateBidConfirmedRides([]));
            return;
          }
          dispatch(driverAllRideActions.updateBidConfirmedRides(result));
        })
        .catch((error) =>
          console.log(
            "ViewRidesWithMyBids.js, Bid Confirmed Rides error",
            error
          )
        );
    };
    updateRidesPending();
    updateRides();
    updateRidesBidConfirmed();

    const interval = setInterval(() => {
      updateRidesPending();
      updateRides();
      updateRidesBidConfirmed();
      // can be changed to any time we want
    }, ONE_SECOND * 15);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const sendLocationUpdates = () => {
      // console.log("Sending location...");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${DRIVER_SEND_CURRENT_LOCATION}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          driver_lat: location?.latitude.toString(),
          driver_lng: location?.longitude.toString(),
        }),
      })
        .then((response) => {
          if (response.ok) {
            // console.log("ViewRidesWithLocation updated at server...");
            return response.json();
          }
          throw response;
        })

        .catch((error) =>
          console.log("ViewRidesWithMyBids.js, Location error", error)
        );
    };

    if (location) {
      sendLocationUpdates();
    }

    const intervalLocation = setInterval(() => {
      if (location) {
        sendLocationUpdates();
      }
      // can be changed to any time we want
    }, LOCATION_UPDATE_TIME_IN_MILLI_SECOND);

    return () => clearInterval(intervalLocation);
  }, [dispatch, location]);

  useEffect(() => {
    const inProgressRideFetchCall = async () => {
      // console.log("Calling in progress call....");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

      const fetchURL = `${BASE_URL}${DRIVER_IN_PROGRESS_RIDES}`;

      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchURL, fetchOptions);

      if (!response.ok) {
        // console.log("Vireposnse not ok...");
        return;
      }

      const data = await response.json();

      // console.log("Data received .. ");
      // console.log(data);

      // console.log("data");
      // console.log(data);
      if (data && data.hasOwnProperty("ride_id")) {
        setShowFloatingActionButton(true);
        setDataForInProgressRide(data?.ride_id);
        // console.log("Automatic navigation to in progress screen...");
        dispatch(driverAllRideActions.setSelectedRide(data?.ride_id));
        navigateToInProgressEndRide();
      } else {
        setShowFloatingActionButton(false);
      }
    };

    inProgressRideFetchCall();

    const intervalInprogress = setInterval(() => {
      inProgressRideFetchCall();
    }, THIRTY_SECONDS);

    return () => clearInterval(intervalInprogress);
  }, [dispatch, navigateToInProgressEndRide]);

  const floatingButtonOnClickHandler = () => {
    // console.log("Clicked ...");

    // show or hide floating action button according to data

    // const inProgressButtonOnClickHandler = () => {
    // console.log("Going to in progress screen...");
    // console.log("Navigate to In progress screen...");
    dispatch(driverAllRideActions.setSelectedRide(dataForInProgressRide));
    navigateToInProgressEndRide();
    // return;
    // };
  };

  useEffect(() => {
    const getVehcileType = async () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const fetchUrl = `${BASE_URL}${GET_DRIVER_VEHICLE_TYPE}`;
      const fetchOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) return;

      const data = await response.json();

      // console.log(data);

      setVehicleTypeData({ vehicleType: data?.vehicle_type });
    };

    getVehcileType();
  }, []);

  const patchVehicleTypeData = async (newVehicleType) => {
    toast.success(" Updating...", {
      toastId: "vehicleUpdate",
      icon: <LoadingSpinner />,
    });
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrl = `${BASE_URL}${DRIVER_CAR_DETAILS}`;
    const fetchOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        vehicle_type: newVehicleType,
      }),
    };

    const response = await fetch(fetchUrl, fetchOptions);

    if (!response.ok) {
      toast.update("vehicleUpdate", {
        render: "Something went wrong",
        type: toast.TYPE.ERROR,
        autoClose: 10000,
        closeButton: true,
        icon: null,
      });
      return;
    }

    toast.update("vehicleUpdate", {
      render: "Update Complete",
      type: toast.TYPE.SUCCESS,
      autoClose: 10000,
      closeButton: true,
      icon: null,
    });
    setVehicleTypeData({ vehicleType: "" });
  };

  const { isOnDuty, changeIsOnDuty } = useOnDutyHook();

  // const isOnDuty = { value: true, status: "success" };
  // const changeIsOnDuty = () => {};

  // const ridesA = [
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Active",
  //     ride_time: "13:33:31",
  //     bid_count: 0,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: null,
  //   },
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Active",
  //     ride_time: "13:33:31",
  //     bid_count: 0,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: null,
  //   },
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Active",
  //     ride_time: "13:33:31",
  //     bid_count: 0,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: null,
  //   },
  // ];
  // pending

  // [
  //   {
  //       "ride_id": 3365,
  //       "customer_id": 58,
  //       "pickup_address": "26.4305271,80.299717",
  //       "drop_address": "26.4378764,80.3358456",
  //       "pickup_address_text": "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //       "drop_address_text": "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //       "ride_date": "2023-10-28",
  //       "distance": "6.2 km",
  //       "ride_status": "Pending",
  //       "ride_time": "13:33:31",
  //       "bid_count": 1,
  //       "payment_id": "",
  //       "amount": "",
  //       "driver_ride_start": null,
  //       "driver_ride_end": null,
  //       "driver_bid_amount": 13900
  //   }
  // ]

  const [rcAvailable, setRCAvailable] = useState(true);

  useEffect(() => {
    const { details } = JSON.parse(localStorage.getItem("_user_driver_rider_"));

    const fetchRCCheck = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}${DRIVER_RC_CHECK}${details}/`
        );
        if (!response.ok) {
          throw new Error("Failed to check RC status");
        }
        const data = await response.json();
        setRCAvailable(data.rc_available);
      } catch (error) {
        console.error("Error checking RC status:", error.message);
      }
    };

    fetchRCCheck();
  }, []);

  const isCampaignPopupModalOpen = useSelector(
    (state) => state.campaignDialogBox.isCampaignPopupModalOpen
  );

  const handleClose = () => {
    dispatch(campaignDialogBoxActions.closeCampaignPopup());
  };

  return (
    <div className={styles.viewRidesWithMyBids}>
      <CampaignDialogBox
        visibility={isCampaignPopupModalOpen}
        onCloseClick={handleClose}
      />
      <OverduePopup />
      {vehicleTypeData?.vehicleType === "Taxi" && (
        <SelectVehicleTypeModal updateButton={patchVehicleTypeData} />
      )}
      {/* {<SelectVehicleTypeModal />} */}

      {!(pendingRideList?.length > 0) && (
        <OnDutyOffDuty isOnDuty={isOnDuty} changeIsOnDuty={changeIsOnDuty} />
      )}

      <LiveBidHeadingWithSort driverName={driverName} />
      {
        <RcInputModal
          rcAvailable={rcAvailable}
          setRCAvailable={setRCAvailable}
        />
      }
      <div className={styles.bidContainer}>
        {!loadingGeoLoc &&
          Array.isArray(bidConfirmedRideList) &&
          bidConfirmedRideList.length > 0 &&
          bidConfirmedRideList.map(
            (eachRide) =>
              !(eachRide?.ride_status === RIDE_STATUS_CANCELLED) && (
                <SingleBidDetailCard
                  key={eachRide?.ride_id}
                  rideId={eachRide?.ride_id}
                  customerId={eachRide?.customer_id}
                  distance={eachRide?.distance}
                  originAddress={eachRide?.pickup_address}
                  destinationAddress={eachRide?.drop_address}
                  originAddressText={eachRide?.pickup_address_text}
                  destinationAddressText={eachRide?.drop_address_text}
                  rideTime={eachRide?.ride_time}
                  bidPlaced={eachRide?.ride_status === RIDE_STATUS_PENDING}
                  bidAcceptedBool={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
                  }
                  numberOfBids={
                    eachRide?.bid_count === 0 ? "No" : eachRide?.bid_count
                  }
                  driverBidAmount={eachRide?.driver_bid_amount}
                  removeGoToDetailsArrow={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED ||
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS ||
                    eachRide?.ride_status === RIDE_STATUS_PENDING ||
                    eachRide?.ride_status === RIDE_STATUS_ACTIVE
                  }
                  rideInProgressBool={
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS
                  }
                  driverCurrentLocation={location}
                  rideActiveBool={eachRide?.ride_status === RIDE_STATUS_ACTIVE}
                  rideInPendingBool={
                    eachRide?.ride_status === RIDE_STATUS_PENDING
                  }
                />
              )
          )}
        {!loadingGeoLoc &&
          Array.isArray(pendingRideList) &&
          pendingRideList.length > 0 &&
          pendingRideList.map(
            (eachRide) =>
              !(eachRide?.ride_status === RIDE_STATUS_CANCELLED) && (
                <SingleBidDetailCard
                  key={eachRide?.ride_id}
                  rideId={eachRide?.ride_id}
                  customerId={eachRide?.customer_id}
                  distance={eachRide?.distance}
                  originAddress={eachRide?.pickup_address}
                  destinationAddress={eachRide?.drop_address}
                  originAddressText={eachRide?.pickup_address_text}
                  destinationAddressText={eachRide?.drop_address_text}
                  rideTime={eachRide?.ride_time}
                  bidPlaced={eachRide?.ride_status === RIDE_STATUS_PENDING}
                  bidAcceptedBool={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
                  }
                  numberOfBids={
                    eachRide?.bid_count === 0 ? "No" : eachRide?.bid_count
                  }
                  driverBidAmount={eachRide?.driver_bid_amount}
                  removeGoToDetailsArrow={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED ||
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS ||
                    eachRide?.ride_status === RIDE_STATUS_PENDING ||
                    eachRide?.ride_status === RIDE_STATUS_ACTIVE
                  }
                  rideInProgressBool={
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS
                  }
                  driverCurrentLocation={location}
                  rideActiveBool={eachRide?.ride_status === RIDE_STATUS_ACTIVE}
                  rideInPendingBool={
                    eachRide?.ride_status === RIDE_STATUS_PENDING
                  }
                />
              )
          )}
        {!loadingGeoLoc &&
          Array.isArray(rideList) &&
          rideList.length > 0 &&
          rideList.map(
            (eachRide) =>
              !(eachRide?.ride_status === RIDE_STATUS_CANCELLED) && (
                <SingleBidDetailCardPlaceBid
                  key={eachRide?.ride_id}
                  rideId={eachRide?.ride_id}
                  customerId={eachRide?.customer_id}
                  distance={eachRide?.distance}
                  originAddress={eachRide?.pickup_address}
                  destinationAddress={eachRide?.drop_address}
                  originAddressText={eachRide?.pickup_address_text}
                  destinationAddressText={eachRide?.drop_address_text}
                  rideTime={eachRide?.ride_time}
                  bidPlaced={eachRide?.ride_status === RIDE_STATUS_PENDING}
                  bidAcceptedBool={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
                  }
                  numberOfBids={
                    eachRide?.bid_count === 0 ? "No" : eachRide?.bid_count
                  }
                  driverBidAmount={eachRide?.driver_bid_amount}
                  removeGoToDetailsArrow={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED ||
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS ||
                    eachRide?.ride_status === RIDE_STATUS_PENDING ||
                    eachRide?.ride_status === RIDE_STATUS_ACTIVE
                  }
                  rideInProgressBool={
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS
                  }
                  driverCurrentLocation={location}
                  rideActiveBool={eachRide?.ride_status === RIDE_STATUS_ACTIVE}
                  rideInPendingBool={
                    eachRide?.ride_status === RIDE_STATUS_PENDING
                  }
                  showSuggestionAcceptRideView={true}
                  commissionRate={eachRide?.commission_rate}
                />
              )
          )}
        {Array.isArray(rideList) && rideList.length !== 0 && (
          <>
            <div
              style={{
                height: "120px", //
              }}
            ></div>
          </>
        )}
        {Array.isArray(rideList) && rideList.length === 0 && (
          <>
            {/* <ErrorInNumber displayText="No active rides in this area" /> */}
            {isOnDuty.value && <HomescreenGoogleMap />}
          </>
        )}
        {/* {!Array.isArray(rideList) && (
          <ErrorInNumber displayText="Data receive error" />
        )} */}
        {/* {false && showFloatingActionButton && (
          <FloatingActionButton
            // styleOptions={{ rightAligned: true }}
            displayText="In Progress"
            floatingButtonOnClick={floatingButtonOnClickHandler}
          />
        )} */}
        {
          <FloatingActionButton2
            floatingButtonOnClick={() => {
              //
              navigate(`${ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
            }}
          />
        }
      </div>
      <BottomAd jsonDataArray={bottomAd} />
    </div>
  );
};

export default ViewRidesWithMyBids;

const FloatingActionButton2 = ({
  floatingButtonOnClick = () => {
    console.log("FloatingActionButton.js, Function not connected");
  },

  styleOptions = {
    leftAligned: false,
    rightAligned: true,
  },
}) => {
  const [flashing, setFlashing] = useState(false);

  useEffect(() => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    //;
    (async () => {
      try {
        const response = await fetch(`${BASE_URL}${API_FLASHING_URL}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();

          if (data?.flashing === true) {
            setFlashing(true);
          } else {
            setFlashing(false);
          }
        }
      } catch (error) {
        setFlashing(false);
      }
    })();
  }, []);
  return (
    <div
      className={`${styles.floatingAction} 
      ${styleOptions?.leftAligned && styles.left}  
      ${styleOptions?.rightAligned && styles.right}
      ${flashing ? styles.flashing : ""} ${styles.greenColorBg}
        `}
      style={{
        bottom: "95px",
        right: "15px",
        fontSize: "1.6rem",
      }}
    >
      <button
        onClick={floatingButtonOnClick}
        className={styles.floatingActionButton}
      >
        {flashing ? "Check your Advance Booking" : "No Advance Booking"}
      </button>
    </div>
  );
};

const RcInputModal = ({ rcAvailable, setRCAvailable }) => {
  const [rcCardFile, setRcCardFile] = useState(null);
  const [rcCardImageName, setRcCardImageName] = useState("");
  const [error, setError] = useState({});

  const { details } = JSON.parse(localStorage.getItem("_user_driver_rider_"));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRcCardFile(file);
    setRcCardImageName(file.name);
    setError({ ...error, rcCardImage: false });
  };

  const handleClose = () => {
    setRCAvailable(true);
  };

  const handleSubmit = async () => {
    if (!rcCardFile) {
      setError({ ...error, rcCardImage: true });
      return;
    }

    const formData = new FormData();
    formData.append("rc", rcCardFile);

    try {
      const response = await fetch(
        `${BASE_URL}${DRIVER_RC_UPLOAD}${details}/`,
        {
          method: "PUT",
          body: formData,
        }
      );
      if (response.ok) {
        setRCAvailable(true);
      }
      // Handle successful upload
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    !rcAvailable && (
      <div className={styles.InputlogsBottomModal}>
        <div className={styles.InputModalBackdrop}>
          <div className={styles.InputVisibleModal}>
            <div className={styles.labelWithInput}>
              <input
                type="file"
                id="rcCard"
                onChange={handleFileChange}
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
            <p className={styles.inputLabel}>Please upload your RC Card</p>
            <label className={styles.fileUploadName} htmlFor={"rcCard"}>
              <span className={styles.labelName}>
                {rcCardFile ? rcCardFile.name : "Select file"}
              </span>
              <img
                className={styles.camera}
                src={images.camera}
                alt="file upload icon"
              />
            </label>
            {error.rcCardImage && (
              <div style={{ marginLeft: "1rem", color: "red" }}>
                Please select RC card image
              </div>
            )}
            <div>
              <button className={styles.button} onClick={handleSubmit}>
                Submit
              </button>
              <button className={styles.button} onClick={handleClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
