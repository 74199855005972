import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  userAgentSlice,
  helpSlice,
  driverAuthSlice,
  fcmTokenSlice,
  driverProfileSlice,
  driverAllRideSlice,
  driverAllBidSlice,
  driverCurrentRideSlice,
  uiElementSlice,
  driverAllRideAdvSlice,
  driverCurrentRideAdvSlice,
  campaignDialogBoxSlice,
} from "./slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    driverAllBid: driverAllBidSlice.reducer,
    driverAllRide: driverAllRideSlice.reducer,
    driverAllRideAdv: driverAllRideAdvSlice.reducer,
    driverAuth: driverAuthSlice.reducer,
    driverCurrentRide: driverCurrentRideSlice.reducer,
    driverCurrentRideAdv: driverCurrentRideAdvSlice.reducer,
    driverProfile: driverProfileSlice.reducer,
    fcmToken: fcmTokenSlice.reducer,
    help: helpSlice.reducer,
    userAgent: userAgentSlice.reducer,
    uiElement: uiElementSlice.reducer,
    // riderAuth: riderAuthSlice.reducer,
    // riderPostRide: riderPostRideSlice.reducer,
    // riderProfile: riderProfileSlice.reducer,
    // riderBid: riderBidSlice.reducer,
    // riderCurrentRide: riderCurrentRideSlice.reducer,
    // riderRating: riderRatingSlice.reducer,
    campaignDialogBox: campaignDialogBoxSlice.reducer,
  },
});

export default store;
